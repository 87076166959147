.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.board {
  display: flex;
  flex-direction: column;

}

.board-row {
  display: flex;
  justify-content: center;
}

.space {
  background: linear-gradient(to bottom,

  #7d6600,
  #085210 15%,
                                        #7d6600,
                                        #085210 75%,
                                        #7d6600
                                        );
  display: flex;
  align-items: center;
  justify-content: center;
}


.corner {
  background-color: green;
  display: flex;
  align-items: center;
  justify-content: center;
  /* pointer-events: none; */
}

.gravityButton {
  display: flex;
}

.up {
  justify-content: top;
}

.left {
  justify-content: left;
}

.slot {
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.active {
  animation: pulse 2s infinite;
}

.inactive {
  opacity: 0.2;
}

.blocked {
  animation: none;
}


.title {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin-bottom: 6rem;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.floating-piece {
  width: 40px;
  height: 40px;
}

.pile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 1rem;
  cursor: pointer;
  color: white;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
  margin-top: 0.5rem;
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
}

.fieldOfPlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 5rem;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom,
                              #002A7D 20%,
                              #8439A5 39%,
                              #FF585B 60%,
                              #ff7433 65%,
                              #9BC04A 66%,
                              #3F7333 100%);
  width: fit-content;
}

.landing-section {
  height: 110vh;
  display: flex;
  justify-content: center;
}

.moon {
  position: relative;
  width: 53rem;
  height: 53rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.moon-background {
  width: 100%;
  height: 100%;
  background: linear-gradient(125deg, #FCFFCF, #E1E6A3, #A3AB3E);
  border-radius: 50%;
  box-shadow: inset -101px 0px 11.7px -35px rgba(0, 0, 0, 0.25);
  filter: blur(2.5px);
  position: absolute;
  top: 0;
  left: 0;
}

.moon-content {
  position: relative;
  z-index: 1;
  color: white;

}

.start {
  display: flex;
  justify-content: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 2.5rem;
  cursor: pointer;
  -webkit-text-stroke: .1rem black;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  margin-top: 0.5rem;
  opacity: 1;
  transition: opacity 0.7s ease-in-out;
}

.main-title {
  text-align: center;
  color: white;
  font-family: 'IrishGrover', sans-serif;
  font-size: 9rem;
  -webkit-text-stroke: .13rem black;
  text-shadow: 10px 15px 5px rgba(0, 0, 0, 0.5);
  margin-bottom: 5rem;
  transition: opacity 0.7s ease-in-out;
}

.fade-out {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.7s ease-in-out;
}

.sunset {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10rem;
  height: 50vh;
}

.boardMiddle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.west-gravity-button {
  margin-right: 1rem;
}

.east-gravity-button {
  margin-left: 1rem;
}

.north-gravity-button {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.south-gravity-button {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

@font-face {
  font-family: 'IrishGrover';
  src: url('./assets/fonts/IrishGrover-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlaywriteDEGrund';
  src: url('./assets/fonts/PlaywriteDEGrund-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  overflow: hidden;
}

.transparent {
  opacity: 0.2;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.star {
  background-image: url('./images/star.png');
  width: 100px;
  height: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  filter: blur(2px);
}

.tutorial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Above all other elements */
}

.tutorial-message {
  background: #fff;
  color: #000;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.tutorial-controls {
  margin-top: 15px;
}

.tutorial-controls button {
  margin: 0 10px;
}

.highlight {
  position: relative;
  z-index: 1001; /* Above the overlay */
  box-shadow: 0 0 10px 5px yellow;
}
